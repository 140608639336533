import React from "react";
import Navbar from "./Navbar";

export default function Layout({ children, location }) {
  return (
    <>
      <Navbar location={location} />
      {children}
    </>
  );
}
